import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61ced20f = () => interopDefault(import('../sites/cvb/pages/user/activate.vue' /* webpackChunkName: "sites/cvb/pages/user/activate" */))
const _a5c31de2 = () => interopDefault(import('../sites/cvb/pages/articleList/index.vue' /* webpackChunkName: "sites/cvb/pages/articleList/index" */))
const _d0b642fa = () => interopDefault(import('../sites/cvb/pages/search/index.vue' /* webpackChunkName: "sites/cvb/pages/search/index" */))
const _1383577b = () => interopDefault(import('../sites/cvb/pages/contact/index.vue' /* webpackChunkName: "sites/cvb/pages/contact/index" */))
const _27b52ebe = () => interopDefault(import('../sites/cvb/pages/checkout/index.vue' /* webpackChunkName: "sites/cvb/pages/checkout/index" */))
const _730d6dfd = () => interopDefault(import('../sites/cvb/pages/branch/index.vue' /* webpackChunkName: "sites/cvb/pages/branch/index" */))
const _6681193d = () => interopDefault(import('../sites/cvb/pages/compare.vue' /* webpackChunkName: "sites/cvb/pages/compare" */))
const _4856f9c1 = () => interopDefault(import('../sites/cvb/pages/login.vue' /* webpackChunkName: "sites/cvb/pages/login" */))
const _0079890a = () => interopDefault(import('../sites/cvb/pages/register.vue' /* webpackChunkName: "sites/cvb/pages/register" */))
const _1acaa84c = () => interopDefault(import('../sites/cvb/pages/passwordReset.vue' /* webpackChunkName: "sites/cvb/pages/passwordReset" */))
const _073fc78f = () => interopDefault(import('../sites/cvb/pages/sitemapconfig.vue' /* webpackChunkName: "sites/cvb/pages/sitemapconfig" */))
const _6f4c2f2a = () => interopDefault(import('../sites/cvb/pages/maintenance.vue' /* webpackChunkName: "sites/cvb/pages/maintenance" */))
const _51f77634 = () => interopDefault(import('../sites/cvb/pages/user/index.vue' /* webpackChunkName: "sites/cvb/pages/user/index" */))
const _bf37ccee = () => interopDefault(import('../sites/cvb/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/cvb/pages/aboutPurchase/index" */))
const _59979917 = () => interopDefault(import('../sites/cvb/pages/passwordForgotten.vue' /* webpackChunkName: "sites/cvb/pages/passwordForgotten" */))
const _761a8bc2 = () => interopDefault(import('../sites/cvb/pages/auth/proxy.vue' /* webpackChunkName: "sites/cvb/pages/auth/proxy" */))
const _4d84a72f = () => interopDefault(import('../sites/cvb/pages/checkout/info.vue' /* webpackChunkName: "sites/cvb/pages/checkout/info" */))
const _4868e473 = () => interopDefault(import('../sites/cvb/pages/checkout/finished.vue' /* webpackChunkName: "sites/cvb/pages/checkout/finished" */))
const _311969c1 = () => interopDefault(import('../sites/cvb/pages/checkout/methods.vue' /* webpackChunkName: "sites/cvb/pages/checkout/methods" */))
const _0752b63b = () => interopDefault(import('../sites/cvb/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/cvb/pages/checkout/importOrder/index" */))
const _debf1138 = () => interopDefault(import('../sites/cvb/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/cvb/pages/user/shoppingLists/index" */))
const _155a09c4 = () => interopDefault(import('../sites/cvb/pages/user/orders/index.vue' /* webpackChunkName: "sites/cvb/pages/user/orders/index" */))
const _6e42bd82 = () => interopDefault(import('../sites/cvb/pages/user/settings.vue' /* webpackChunkName: "sites/cvb/pages/user/settings" */))
const _4febb7a8 = () => interopDefault(import('../sites/cvb/pages/user/dashboard.vue' /* webpackChunkName: "sites/cvb/pages/user/dashboard" */))
const _0341f474 = () => interopDefault(import('../sites/cvb/pages/user/addressList.vue' /* webpackChunkName: "sites/cvb/pages/user/addressList" */))
const _6a5cb892 = () => interopDefault(import('../sites/cvb/pages/user/password.vue' /* webpackChunkName: "sites/cvb/pages/user/password" */))
const _389fa32f = () => interopDefault(import('../sites/cvb/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/cvb/pages/checkout/payment/callback" */))
const _7eaf56aa = () => interopDefault(import('../sites/cvb/pages/index.vue' /* webpackChunkName: "sites/cvb/pages/index" */))
const _b025fa68 = () => interopDefault(import('../sites/cvb/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/cvb/pages/user/shoppingLists/_id" */))
const _70a7ad2c = () => interopDefault(import('../sites/cvb/pages/user/orders/_id.vue' /* webpackChunkName: "sites/cvb/pages/user/orders/_id" */))
const _36ac68ee = () => interopDefault(import('../sites/cvb/pages/article/_slug.vue' /* webpackChunkName: "sites/cvb/pages/article/_slug" */))
const _a9306072 = () => interopDefault(import('../sites/cvb/pages/articleList/_slug.vue' /* webpackChunkName: "sites/cvb/pages/articleList/_slug" */))
const _7156ccb5 = () => interopDefault(import('../sites/cvb/pages/branch/_slug.vue' /* webpackChunkName: "sites/cvb/pages/branch/_slug" */))
const _a5442f7c = () => interopDefault(import('../sites/cvb/pages/product/_slug.vue' /* webpackChunkName: "sites/cvb/pages/product/_slug" */))
const _26887562 = () => interopDefault(import('../sites/cvb/pages/page/_slug.vue' /* webpackChunkName: "sites/cvb/pages/page/_slug" */))
const _58108092 = () => interopDefault(import('../sites/cvb/pages/_.vue' /* webpackChunkName: "sites/cvb/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _61ced20f,
    name: "user-activate___cs"
  }, {
    path: "/clanky",
    component: _a5c31de2,
    name: "articleList___cs"
  }, {
    path: "/hledat",
    component: _d0b642fa,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _1383577b,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _27b52ebe,
    name: "checkout___cs"
  }, {
    path: "/pobocky",
    component: _730d6dfd,
    name: "branch___cs"
  }, {
    path: "/porovnat",
    component: _6681193d,
    name: "compare___cs"
  }, {
    path: "/prihlasit",
    component: _4856f9c1,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _0079890a,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _1acaa84c,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _073fc78f,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _6f4c2f2a,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _51f77634,
    name: "user___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _bf37ccee,
    name: "aboutPurchase___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _59979917,
    name: "passwordForgotten___cs"
  }, {
    path: "/auth/proxy",
    component: _761a8bc2,
    name: "auth-proxy___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _4d84a72f,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _4868e473,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _311969c1,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _0752b63b,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _debf1138,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _155a09c4,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _6e42bd82,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _4febb7a8,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _0341f474,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _6a5cb892,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _389fa32f,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _7eaf56aa,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _b025fa68,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _70a7ad2c,
    name: "user-orders-id___cs"
  }, {
    path: "/clanek/:slug",
    component: _36ac68ee,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _a9306072,
    name: "articleList-slug___cs"
  }, {
    path: "/pobocky/:slug",
    component: _7156ccb5,
    name: "branch-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _a5442f7c,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _26887562,
    name: "page-slug___cs"
  }, {
    path: "/*",
    component: _58108092,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
